@import "./font.scss";
@import "./icon.scss";

* {
  box-sizing: border-box !important;
}

.col {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
}

.row {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-reverse {
  flex-direction: row-reverse;
}

.v-ctr {
  align-items: center;
}

.h-ctr {
  justify-content: center;
}

.v-start {
  align-items: flex-start;
}

.v-end {
  align-items: flex-end;
}

.h-btn {
  justify-content: space-between;
}

.v-btn {
  align-items: space-between;
}

.h-arnd {
  justify-content: space-around;
}

.h-end {
  justify-content: flex-end;
}

.flex-full,
.f-rest {
  flex: 1;
}

.fwarp {
  flex-wrap: wrap;
}

.f-rest-2 {
  flex: 2;
}

.o-hide {
  overflow: hidden;
}

.oy-auto {
  overflow: hidden auto;
}

.text-nxt {
  word-break: break-all;
}

.btn {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

.ant-app {
  font-size: inherit;
  font-family: "Roboto";
  color: inherit;
}

.w-100 {
  width: 100%;
}

.w-49 {
  width: 49%;
}
.w-37 {
  width: 37%;
}

.h-50 {
  height: 48%;
}

.h-100 {
  height: 100%;
}

.cFFF {
  color: #fff;
}

.cFE3333 {
  color: #fe3333;
}

.cc0000 {
  color: #cc0000;
}

.c0133CC {
  color: #0133cc;
}

.c777777 {
  color: #777777;
}

.c1155CC {
  color: #1155cc;
}

.c00085 {
  color: rgba(0, 0, 0, 0.85);
}

.c00045 {
  color: rgba(0, 0, 0, 0.45);
}

.c00025 {
  color: rgba(0, 0, 0, 0.25);
}

.c00073 {
  color: #00000073;
}

.c000 {
  color: #000;
}

.c999 {
  color: #999999;
}

.cBFBFBF {
  color: #bfbfbf;
}

.c1890FF {
  color: #1890ff;
}

.c444444 {
  color: #444444;
}

.cBFBFBF {
  color: #bfbfbf;
}

.cBB2222D9 {
  color: #bb2222d9;
}

.cC3C3C3 {
  color: #c3c3c3;
}

.c989898 {
  color: #989898;
}

.bgOverall {
  background-color: #1e686d;
}

.c1E686D {
  color: #1e686d;
}

.c44BB44 {
  color: #4b4;
}

.bg-primary {
  background-color: #0133cc;
}

.bg59A7AC {
  background-color: #59a7ac;
}

.bgWhite {
  background-color: #fff;
}

.bgOutline,
.bgDraft {
  background-color: #871211;
}

.bgReview {
  background-color: #fe7733;
}

.bgapproved,
.bgApproved {
  background-color: #fa8c16;
}

.bgPublished,
.bgActive {
  background-color: #33cc77;
}

.bgInactive,
.bgconfidential,
.bgConfidential {
  background-color: #871211;
}

.bgpersonal,
.bgPersonal {
  background-color: #871211;
}

.bgrestricted,
.bgRestricted {
  background-color: #fe7733;
}

.bginternal,
.bgInternal {
  background-color: #fefe77;
}

.bgpublic,
.bgPublic {
  background-color: #33cc77;
}

.bgCFE3333 {
  background-color: #fe3333;
}

.bgarchived,
.bgArchived {
  background-color: #adadad;
}

.bgDDFFFF {
  background-color: #ddffff;
}

.bgTransparent {
  background-color: transparent;
}

.btn-menu {
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  height: 30px;
  width: 32px;
  font-size: 3px;
  margin-left: 16px;

  .icon-dot {
    margin-top: 6px;
  }
}

.bgFFDDDD {
  background-color: #ffdddd;
}

.bg238787 {
  background-color: #238787;
}

.bg882222 {
  background-color: #882222;
}

.bgpending,
.bgPending {
  background-color: #ff0200;
}

.bgstarted,
.bgStarted {
  background-color: #fe7733;
}

.bgcompleted,
.bgpassed,
.bgCompleted {
  background-color: #00ff19;
}

.severity.emergency-text {
  color: #c00;
}

.severity.critical,
.severity.emergency {
  background-color: rgba(187, 34, 34, 1) !important;
  color: #fff !important;

  &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(187, 34, 34, 0.9);
  }
}

.severity.ant-select-disabled {
  .ant-select-selector {
    color: inherit !important;
  }
}

.severity.high {
  color: #fff !important;
  background-color: rgba(255, 85, 85, 1) !important;

  &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(255, 85, 85, 0.9);
  }
}

.severity.medium {
  color: #fff !important;
  background-color: rgba(255, 136, 34, 1) !important;

  &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(255, 136, 34, 0.9);
  }
}

.severity.low {
  color: #000 !important;
  background-color: rgba(255, 255, 119, 1) !important;
  border: 0.5px solid #777;

  &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(255, 255, 119, 0.9);
  }

  .icon-caret-down {
    color: #777;
  }
}

.severity.minimal {
  color: #000 !important;
  background-color: rgba(255, 255, 204, 1) !important;
  border: 0.5px solid #777;

  &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(255, 255, 204, 0.9);
  }

  .icon-caret-down {
    color: #777;
  }
}

.severity.none {
  background-color: rgba(119, 119, 119, 1) !important;
  color: #fff !important;

  &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(119, 119, 119, 0.9);
  }
}

.status.open {
  color: #bb2222 !important;
}

.status.inprogress {
  color: #ff5500 !important;
}

.status.closed {
  color: #228822 !important;
}

.status.invalid {
  color: #777777 !important;
}

.task-status {
  &.open,
  &.pending {
    color: #fc4343;
  }

  &.responded,
  &.revision {
    color: #fcbc03;
  }

  &.complete,
  &.compliant {
    color: #87fc87;
  }

  &.skipped,
  &.abandoned,
  &.duplicate,
  &.redundant {
    color: #787878;
  }

  &.review {
    color: #ff8844;
  }

  &.reviewing {
    color: #fcfc87;
  }

  &.reviewed {
    color: #43dd43;
  }

  &.closed {
    color: #228822;
  }

  &.inprogress {
    color: #ff5500;
  }

  &.hold {
    color: #cc0303;
  }
}

.priority {
  &.critical {
    background-color: #cc0000;
    color: #f7f7f7;
  }

  &.high {
    background-color: #ff0000;
    color: #f7f7f7;
  }

  &.low {
    background-color: #ff9900;
    color: #030303;
  }

  &.minimal {
    background-color: #00b050;
    color: #030303;
  }

  &.none {
    background-color: #888888;
    color: #f7f7f7;
  }

  &.medium {
    background-color: #ff8888;
    color: #030303;
  }

  &.default {
    background-color: #888888;
    color: #030303;
  }
}

.c882222 {
  color: #882222;
}

.c777 {
  color: #777;
}

.cinherit {
  color: inherit;
}

.c0033CC {
  color: #0033cc;
}

.c238787 {
  color: #238787;
}

.cDDD {
  color: #ddd;
}

.cD9D9D9 {
  color: #d9d9d9;
}

.capital,
.caps {
  text-transform: capitalize;
}

.tup {
  text-transform: uppercase;
}

.txt-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.screen {
  position: relative;
}

.progress-cnt {
  position: absolute;
}

.api-progress {
  position: relative !important;
  visibility: hidden;

  &.show {
    visibility: visible;
  }
}

.screen-pad {
  padding: 1.6rem 2.4rem;
}

.doc-nav {
  background: #ddffff;
  width: 4.6rem;
  padding-top: 10.2rem;
  transition: width 0.4s ease-in-out;

  &:hover {
    width: 14rem;
  }

  .item {
    width: 100%;
    height: 4.8rem;
    margin-bottom: 8px;
    padding-left: 1.4rem;
    background-color: transparent;

    .icon {
      margin-right: 1.6rem;
    }

    &.active {
      background-color: #173436;
    }
  }
}

.txt-justify {
  text-align: justify;
}

.ck-container {
  p {
    margin: 0;
  }

  .title {
    margin-bottom: 0.8rem;
  }

  * {
    box-sizing: unset !important;
  }
}

p.ck-container {
  margin: 0;
  font-size: unset;
  font-family: unset;

  &.max {
    max-height: 180px;
  }
}

.no-access {
  padding: 8rem;

  .btn {
    align-self: unset;
    margin-top: 3rem;
  }

  .title {
    font-size: 10rem;
    font-style: italic;
    color: #238787;
  }

  .text-sec {
    width: 60%;
    padding-top: 11rem;
  }

  .img-sec {
    width: 40%;
  }

  .eval {
    max-width: 400px;
    max-height: 600px;
  }
}

.ant-table-filter-column,
.ant-table-column-sorters {
  font: inherit !important;
  color: inherit !important;

  .ant-table-column-title {
    font: inherit !important;
    color: inherit !important;
  }
}

.ant-table-filter-column {
  .ant-table-filter-trigger {
    margin-right: 8px;
  }
}

.ant-table-thead .text-center {
  text-align: center !important;
}

.ant-table-body {
  .ant-table-cell {
    vertical-align: top;
  }

  .btn {
    padding: 0;
  }
}

.ant-table-cell {
  padding: 1rem 16px !important;
}

.ant-dropdown {
  z-index: 1300;
}

.select-popup {
  padding: 4px 0;
  background-color: #fff !important;

  .ant-select-item-option {
    border-radius: 0;
  }
}

.select-no-found {
  border-radius: 1px;
  padding: 4px;
}

.cke_toolbar {
  * {
    box-sizing: unset !important;
    font-family: unset;
  }

  p:first-child {
    // display: none;
  }

  &.tbl_of_content {
    p {
      width: 100%;
      clear: both;

      span:nth-child(2) {
        float: right;
      }
    }
  }
}

.cke_editable {
  padding: 0 10px;
}

.new-content {
  .header {
    padding-bottom: 1.6rem;

    .title-input {
      margin: 0;
      width: 60rem;

      .label-cont {
        display: none;
      }

      .container {
        padding-left: 0;
      }
    }

    .btn:nth-child(2) {
      margin: 0 1.6rem;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.ant-table-placeholder .ant-table-cell {
  border-bottom: none;
}

.no-scroll-bar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.no-scroll-bar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.ant-tabs-nav::before {
  border-bottom-color: transparent !important;
}

.pdf-view-container {
  // width: 100%;
  width: 1020px;
  margin: 0 auto;
}

.txt-no-wrap {
  white-space: nowrap;
  overflow: hidden;
  /* Hides any content that overflows the container */
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

.mt2 {
  margin-top: 20px;
}
