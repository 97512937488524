.btn {
  padding: 4px 15px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  align-self: flex-start;
  &.min{
    min-width: 74px;
  }
  .label{
    line-height: 2.2rem;
    font: inherit;
  }
  &.bg-lite{
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled, &.btn-disabled{
    cursor: not-allowed;
    background: #F5F5F5;
    color: rgba(0, 0, 0, 0.25);
  }
  &.no-border{
    box-shadow: none !important;
  }
  .icon-text{
    margin-right: 1rem;
  }
  .icon{
    font: inherit
  }
  &.gap{
    margin: 0 8px;
  }
}