.inc-res-modal {
  .ant-modal-content {
    border-radius: 0;
    padding: 16px;
  }

  .ant-modal-title {
    margin-left: 8px;
  }

  .ant-modal-close {
    top: 26px;
    right: 24px;
    width: 64px;
  }

  .btn-menu {
    width: 28px;
    height: 24px;
    border-color: #0133CC;
    margin-left: 0;
    padding-top: 3px !important;

    .icon-dot {
      font-size: 2px;
    }
  }
}

.resp-menu {
  .menu-list {
    padding: 0;
  }

  .container {
    width: 300px;
    min-height: 100px;

    .footer {
      border-top: 1px solid #F0F0F0;
    }
  }

  .user-info {
    padding: 16px;
  }
}

.change-res-modal {
  .desc {
    margin-top: 4px;
    margin-bottom: 16px;
  }
}

.new-inc-modal {
  .form {
    padding-top: 16px;

    .ant-form-item-label {
      width: 90px;
    }

    .severity .ant-form-item-label {
      width: 70px;
    }
  }

  .label-cont {
    min-width: 96px !important;

    .label {
      width: 90%;
      text-align: right;
    }
  }
}

.view-inc {
  .header {
    padding: 16px 24px;

    .btn-back {
      padding-left: 0;
    }

    .fields {
      .form-input {
        width: 186px;
        margin-left: 24px;
        margin-bottom: 0;
      }
    }
  }

  .side-bar {
    &.close {
      width: 55px;
      padding: 20px 5px 0 0px;
      transition-timing-function: ease-out;

      .sidebar-container {
        transition-timing-function: ease-out;
        opacity: 0;
      }
    }

    &.open {
      padding: 20px 5px 0 16px;
      transition-timing-function: ease-in;

      .sidebar-container {
        transition-timing-function: ease-out;
        opacity: 1;
      }
    }

    .sidebar-container {
      transition: opacity 0.25s;
    }

    width: 306px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    transition: width 0.25s,
    padding 0.25s;
    background-color: #FFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

    .footer {
      padding-top: 12px;
      box-shadow: 0px 1px 0px 0px #F0F0F0 inset;
    }

    .field {
      margin-bottom: 20px;

      .key-label {
        width: 30%;
        text-align: right;
        margin-right: 12px;
      }

      .btn-add-resp {
        border: 1px solid #0133CC;
        padding: 1px 4px;
        line-height: 12px;
        align-self: flex-end;
        margin-top: 4px;
      }
    }

    .btn-resp-del {
      padding: 0 4px;
    }
  }

  .container {
    background-color: #F9F9F9;
    position: relative;
    padding-top: 2px;
  }

  .history-sec {
    width: 380px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    background: #FFF;
    position: relative;
    z-index: 1;

    .header {
      height: 56px;
      padding: 0 24px;
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
    }

    .footer {
      padding: 10px 20px 4px 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.06);

      .btn-submit {
        position: relative;
        top: -10px;
      }
    }

    .documents.documents .select-btn {
      min-width: 100px;
    }

    .avator {
      width: 36px;
      height: 32px;
      border-radius: 16px;
      background-color: #DDFFFF;
      border: 1px solid #238787;
      margin-right: 12px;
    }

    .comment-card {
      .avator {
        width: 32px;
      }

      .link-evd {
        padding: 0;
        margin-top: 8px;

        span {
          display: block;
          width: 100%;
          word-break: break-all;
        }
      }

      .comment-text {
        margin-bottom: 0;
        margin-top: 4px;
      }

      padding: 24px 20px 0px 20px;
    }
  }

  .toggle-sec {
    position: relative;
    z-index: 1;
    padding: 8px 10px;
    width: calc(100% - 50px);

    .link-evd {
      padding: 0;
    }

    .panel {
      border-radius: 1px;

      .ant-collapse-content {
        padding: 0px;
      }

      .ant-collapse-content-box {
        padding: 16px;
      }
    }

    .collapse {
      margin-bottom: 16px;
      border-color: #D9D9D9;
      border-radius: 1px;
      background-color: #FAFAFA;

      .ant-collapse-item:last-child>.ant-collapse-content {
        border-radius: 0 0 1px 1px;
      }
    }
  }

  .details-form {
    .form-input .ant-form-item-label {
      width: 94px;
    }

    .form-input.rca {
      .ant-form-item-label>label::after {
        margin-top: 22px;
      }
    }

    .summary .textarea-input {
      text-align: justify;
    }
  }

  .task-view {
    .create-task-btn {
      margin-left: 16px;
    }

    // .ant-collapse-content-box {
    //   max-height: 320px;
    // }

    .doc-table {
      border-radius: 2px;
      margin-top: 8px;
      border: 1px solid rgba(0, 0, 0, 0.06);
    }
  }
}

.create-task-form {
  .form-input .label {
    // width: 81px;
  }

  .ant-form-item-label {
    width: 96px;
  }

  .description {
    .ant-form-item-required {

      &::after,
      &::before {
        margin-top: 24px;
      }
    }
  }

  .field-title {
    width: 70%;
  }

  .field-priority {
    width: 30%;

    .ant-form-item-label {
      width: 72px;
      margin-left: 16px;
    }
  }

  .field-dept {
    width: 25%;
  }

  .field-source {
    width: 45%;

    .ant-form-item-label {
      width: 72px;
      margin-left: 16px;
    }
  }

  .field-due {
    width: 30%;

    .ant-form-item-label {
      margin-left: 16px;
    }
  }
}

.export-form {
  .form-input .label {
    width: 64px;
  }
}

.inc-stats {
  width: 100%;
  max-width: 829px;
  padding: 0 24px;

  .btn-export {
    margin-bottom: 8px;
    padding-right: 0px;

    .icon {
      margin: 0 0 0 8px;
    }
  }

  .form-input {
    width: 200px;
    margin: 0;
  }

  .title {
    min-width: 190px;
  }

  .gap {
    margin-bottom: 60px;
  }

  .stats-tbl {
    table-layout: fixed;
    border-spacing: 0;
    margin-top: 24px;

    tr .cell {
      padding: 8px;
      border-top: 1px solid #238787;
      border-left: 1px solid #238787;

      &:last-child {
        border-right: 1px solid #238787;
      }

      &.no-border {
        border: none;
      }
    }

    tbody tr {
      .cell {
        &:not(:first-child) {
          border-left: none;
        }

        &:first-child {
          border-right: 1px solid #238787;
        }
      }

      &:last-child {
        .cell {
          border-bottom: 1px solid #238787;
        }
      }

      &:nth-child(even) {
        .cell {
          background-color: #DFF;
        }
      }
    }
  }
}

.share-confirm {
  .emails {
    padding: 0px 0 0 20px;
    margin-top: 2px;
  }
}

// .responder-popup{
//   border-radius: 1px;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   .ant-modal-content{
//         border-radius: 1px;
//       }
// }
.hr-styled {
  border: 0;
  height: 1px;
  background-color: #ece9e9;
  margin: 20px 0;
}

.modal-content {
  position: relative;
}

.button-wrapper {
  position: relative;
  display: inline-block;
  padding: 0px !important;//

  .label {
    font-weight: 700;
    font-size: 14px;
    padding-right: 5px;
    margin-top: -3px;
  }

  .gif-loader {
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    z-index: 1;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    border-radius: 10px;
  }

  .button-container {
    position: relative;
    z-index: 2;
    padding: 0px !important;//
  }

  .custom-ai-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #EEFFFF;
    // border: none;
    color: #0088FF;
    // padding: 0.6rem 1.5rem;
    // font-size: 16px;
    // font-weight: 500;
    border-radius: 12px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    border: 1px solid rgb(0, 153, 254);
    width: 200px;

    .icon {
      margin-left: 0.5rem;

      img {
        width: 24px;
        height: 24px;
      }
    }
    .divider-dropdown-wrapper {
      display: flex;
      align-items: center;
    }
    .divider {
      width: 1px;
      height: 28px;
      background-color: #b3e5fc;
      margin-left: 10px;
      margin-right: 5px;
    }

    .dropdown-arrow {
      color: #1133CC;
      margin-left: 0.5rem;
      cursor: pointer;
      font-size: 12px;
      z-index: 3;
    }

  }

  .dropdown-menu {
    position: absolute;
    top: calc(100%);
    right: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
    z-index: 1;
    width: 80%;
    padding: 0;
  }

}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  /* Optional background overlay */
  z-index: 10000;
  /* Ensure it appears over the modal */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
}

.popup-wrapper {
  position: relative;
  width: 459px;
  /* Match with popup width */
  height: 416px;
  /* Match with popup height */
}

.popup-gif-loader {
  position: absolute;
  top: -3px;
  /* Adjust to fit the popup's border */
  left: -3px;
  right: -6px;
  bottom: -6px;
  z-index: 1;
  width: calc(100% + 10px);
  /* Extend slightly beyond popup to create a border effect */
  height: calc(100% + 10px);
  /* Extend slightly beyond popup to create a border effect */
  border-radius: 25px;
  transform: translateY(-150px);
}

.popup-content {
  position: relative;
  z-index: 2;
  /* Popup content above the GIF */
  width: 100%;
  /* Fill the wrapper */
  height: 100%;
  /* Fill the wrapper */
  transform: translateY(-150px);
  opacity: 1.0 !important;
}

.aiml-popup {
  width: 100%;
  height: 100%;
}

.warning-container {
  display: flex;
  // align-items: center;
  margin-top: 4px;
  /* Adjust space between the button and warning text */
  justify-content: start;
  font-size: 12px;
  /* Small font for the warning text */
  // color: #FF5A3C; /* Adjust the warning text color to be more readable */
  // text-align: center; /* Center-align the text */
}

.warning-outlined {
  width: 14.1px;
  height: 12.25px;
  margin-right: 6px;
  margin-top: 3px;
  /* Space between icon and text */
}

.warning-text {
  display: flex;
  justify-content: start;
  text-align: left;
  font-weight: 400;
  line-height: 1.2;
  color: #989898;
  font-size: 9px;
}

.footer-wrapper {
  height: 60px;
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.update-eval-btn {
  position: relative;
  z-index: 2;
  background-color: #EEFFFF; /* Make sure the button has its styling */
  // border-radius: 12px;
  // border: 1px solid blue;
  height: 32px;
  width: 168px !important;
}

.gif-loader {
  position: absolute;
  top: -6px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  width: calc(100%);
  height: calc(100%);
  z-index: 1;
  border-radius: 18px !important;
}