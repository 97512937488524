.form-input {
  margin-bottom: 16px;
  .label {
    word-wrap: break-word;
  }
  .ant-form-item-required {
    &::before {
      display: none !important;
    }
    &::after {
      display: inline-block;
      color: #ff4d4f;
      font-size: 1.4rem;
      line-height: 1;
      content: "*" !important;
      margin-inline-end: 12px;
    }
  }
  &.type-file {
    .select-btn {
      min-width: 120px;
      height: 36px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &.type-text,
  &.type-search {
    .ant-form-item-required {
    }
  }
  .border {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  .textarea-input {
    resize: none;
    &.read-only {
      border: none;
    }
  }
  .select-input .ant-select-selector {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  .select-input {
    padding-left: 0;
    height: 36px;
  }
  .select-input .ant-select-selection-search-input {
    height: inherit;
  }
  .select-input .ant-select-selector,
  .text-input {
    outline: none;
    color: inherit;
    line-height: 12px;
    width: 100%;
    height: 36px;
    resize: none;
    background-color: transparent;
    margin-top: 0;
    &.read-only {
      border: none;
      height: auto;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: #efefef;
    }
    .input-ele {
      padding: 4px 8px;
      background-color: transparent;
      color: inherit;
    }
    &.disabled.read-only {
      background-color: transparent;
    }
  }
  .text-input {
    &.no-pad {
      padding: 0;
    }
    .input-suffix {
      padding: 0 4px;
      border-left: 1px solid #d9d9d9;
    }
    &:focus {
      outline: none;
      border-color: #d9d9d9;
    }
  }
  .file-list {
    max-height: 200px;
    .file-icon {
      margin-right: 8px;
    }
    .file-entry {
      // width: calc(100% - 60px);
      padding: 4px 8px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      margin-bottom: 8px;
    }
    .btn-delete {
      cursor: pointer;
      padding: 4px;
      align-self: unset;
      margin-left: 8px;
    }
  }
  .cke_editable {
    min-height: 90px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    background: #fff;
    outline: none;
    margin-top: -10px !important;
  }
  .suffix-pass-input {
    .pass-suffix {
      width: 40%;
      height: 36px;
      border: 1px solid #d9d9d9;
      border-right: none;
      border-radius: 0 0 0 0;
      background-color: #fafafa;
    }
    .input-suffix {
      border: none;
      cursor: pointer;
    }
  }
  &.req-colon {
    .ant-form-item-required {
      margin-right: 8px;
      &::before {
        display: inline-block !important;
        content: ":" !important;
        color: inherit !important;
        position: absolute;
        left: 95%;
      }
    }
  }
}

.dropdown-list {
  .option[aria-selected="true"] {
    background-color: transparent !important;
    color: #0033cc;
  }
}

.dropdown {
  border: 1px solid #d9d9d9;

  .icon {
    margin-right: 0.8rem;
  }

  &:focus,
  &:hover {
    outline: none !important;
    border: 1px solid #d9d9d9;
  }
}

.dropdown-item {
  .icon,
  .label {
    color: inherit;
  }

  .icon {
    margin-right: 0.8rem;
  }

  &.Mui-selected {
    background-color: transparent !important;
  }

  &.primary:hover {
    background-color: #fff !important;

    .icon,
    .label {
      color: #0033cc;
    }
  }
}
