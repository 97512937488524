@font-face {
    font-family: 'icomoon';
    src: url('./assets/fonts/icomoon.eot?sifyyl');
    src: url('./assets/fonts/icomoon.eot?sifyyl#iefix') format('embedded-opentype'),
      url('./assets/fonts/icomoon.ttf?sifyyl') format('truetype'),
      url('./assets/fonts/icomoon.woff?sifyyl') format('woff'),
      url('./assets/fonts/icomoon.svg?sifyyl#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"],
  [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-lock:before {
    content: "\e919";
  }
  
  .icon-file-csv:before {
    content: "\e920";
  }
  
  .icon-copy:before {
    content: "\e91f";
  }
  
  .icon-warning-icon:before {
    content: "\e91e";
  }
  
  .icon-back:before {
    content: "\e91d";
  }
  
  .icon-contents:before {
    content: "\e91c";
  }
  
  .icon-circular:before {
    content: "\e914";
  }
  
  .icon-evaluation:before {
    content: "\e91a";
  }
  
  .icon-learning:before {
    content: "\e91b";
  }
  
  .icon-caret-down:before {
    content: "\e900";
  }
  
  .icon-caret-up:before {
    content: "\e901";
  }
  
  .icon-close-eye:before {
    content: "\e902";
  }
  
  .icon-delete:before {
    content: "\e903";
  }
  
  .icon-drive:before {
    content: "\e904";
  }
  
  .icon-edit-pencil:before {
    content: "\e905";
  }
  
  .icon-export:before {
    content: "\e906";
  }
  
  .icon-file-pdf:before {
    content: "\e907";
  }
  
  .icon-open-eye:before {
    content: "\e908";
  }
  
  .icon-open-folder:before {
    content: "\e909";
  }
  
  .icon-plus-lite:before {
    content: "\e90a";
  }
  
  .icon-profile-caret:before {
    content: "\e90b";
  }
  
  .icon-search:before {
    content: "\e90c";
  }
  
  .icon-send:before {
    content: "\e90d";
  }
  
  .icon-share:before {
    content: "\e90e";
  }
  
  .icon-stats:before {
    content: "\e90f";
  }
  
  .icon-upload:before {
    content: "\e910";
  }
  
  .icon-dot:before {
    content: "\e911";
  }
  
  .icon-download:before {
    content: "\e912";
  }
  
  .icon-save:before {
    content: "\e913";
  }
  
  .icon-check-o:before {
    content: "\e915";
  }
  
  .icon-variables:before {
    content: "\e916";
  }
  
  .icon-presentations:before {
    content: "\e917";
  }
  
  .icon-open-folder-o:before {
    content: "\e918";
  }
  
  .icon-open-folder1:before {
    content: "\e919";
  }