.doc-table {
  .cell {
    width: 100%;
  }
  .cel-circle{
    width: 12px;
    height: 12px;
    margin-right: 0.8rem;
    border-radius: 50%;
  }
}
.table-ct{
  padding: 0.8rem 0;
  .page-change{
    margin: 16px 0;
  }
}
.data-table-search{
  padding: 0.8rem;
  .input{
    margin-bottom: 0;
  }
  .label-cont{
    display: none;
  }
  .reset{
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    margin: 0 0.8rem;
  }
}
.ant-table-filter-column,
.ant-table-column-sorters {
  font: inherit !important;
  color: inherit !important;

  .ant-table-column-title {
    font: inherit !important;
    color: inherit !important;
  }
}